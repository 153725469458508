import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import ErrorPage from './pages/ErrorPage';
import NewsPage from './pages/NewsPage';
import EventsPage from './pages/EventsPage';
import MembershipPage from './pages/MembershipPage';
import ContactPage from './pages/ContactPage';
import BoardPage from './pages/BoardPage';
import FeesPage from './pages/FeesPage';
import ImpressumPage from './pages/ImpressumPage';
import WelcomePage from './pages/WelcomePage';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <WelcomePage />
      },
      {
        path: "aktuelles",
        element: <NewsPage />
      },
      {
        path: "termine",
        element: <EventsPage />
      },
      {
        path: "mitgliedschaft",
        element: <MembershipPage />
      },
      {
        path: "kontakt",
        element: <ContactPage />
      },
      {
        path: "vorstand",
        element: <BoardPage />
      },
      {
        path: "beitraege",
        element: <FeesPage />
      },
      {
        path: "impressum",
        element: <ImpressumPage />
      }
    ]
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
