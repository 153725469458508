function ContactPage(){

    return (
        <div>
            <h2>Kontakt</h2>
            <p>
                Max Reinhardt<br />
                Dierbachweg 18<br />
                76870 Kandel<br />
                <a href="mailto:vorstand@kanuverein-suew.de" >mailto:vorstand@kanuverein-suew.de</a>
            </p>
        </div>
    );
}

export default ContactPage;