function ImpressumPage(){

    return (
        <div>
            <h1>Impressum</h1>
            <h3>Kanuverein Südliche Weinstraße e.V.</h3>
            <p>
                Bootshaus<br />
                Auf dem Viertel 11<br />
                76887 Bad Bergzabern
            </p>
            <p>
                <b>Gemeinschaftlich vertretungsberechtigt:</b><br />
                <b>1. Vorsitzender</b><br />
                Max Reinhardt<br />
                Dierbachweg 18<br />
                76870 Kandel<br />
                <b>Mail:</b> <a href="mailto:vorsitzende@kanuverein-suew.de" >mailto:vorsitzende@kanuverein-suew.de</a>
            </p>
            <p>
                <b>2. Vorsitzender</b><br />
                Manuel Seibel<br />
                <b>Mail:</b> <a href="mailto:vorsitzende@kanuverein-suew.de" >mailto:vorsitzende@kanuverein-suew.de</a>
            </p>
        </div>
    );
}

export default ImpressumPage;