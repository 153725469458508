function NewsPage(){

    return (
        <div>
            <h2>Aktuelles</h2>
            <p>Auf Grund der anhaltenden technischen Probleme des Freibads des Rebmeerbad Bad Bergzabern <i>(Friedrich-Ebert-Straße 40, 76887 Bad Bergzabern)</i> findest das Training aktuell im Schwimmerbecken zu folgenden geänderten Zeiten statt: <b>wöchentlich Mittwoch von 19:30 - 20:30 Uhr statt. Treffpunkt ist 19:00 Uhr vor dem Eingang zum Freibad.</b></p>
        </div>
    );
}

export default NewsPage;