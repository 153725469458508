import "./FeesPage.css"

function FeesPage(){

    return (
        <div>
            <h1>Mitgliedsbeiträge</h1>
            <p><i>Stand Januar 2019</i></p>
            <table className="fees-table">
                <thead>
                    <tr>
                        <th></th>
                        <th>Einmalige Aufnahmegebühr</th>
                        <th>Jahres-Mitgliedsbeitrag</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Familien</td>
                        <td>55,00 €</td>
                        <td>100,00 €</td>
                    </tr>
                    <tr>
                        <td>Erwachsene</td>
                        <td>45,00 €</td>
                        <td>75,00 €</td>
                    </tr>
                    <tr>
                        <td>Jugendliche<br /><span>(14 - 18 Jahre)</span></td>
                        <td>15,00 €</td>
                        <td>50,00 €</td>
                    </tr>
                    <tr>
                        <td>Kinder<br /><span>(unter 14)</span></td>
                        <td>keine</td>
                        <td>25,00 €</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default FeesPage;