import "./EventsPage.css"

function EventsPage(){

    return (
        <div>
            <h2>Fahrten und Aktionen 2024</h2>
            <table className="events-table">
                <tbody>
                    <tr>
                        <td>10.03.2024</td>
                        <td>Wanderung, 11 Uhr Reisdorf</td>
                    </tr>
                    <tr>
                        <td>23.03. - 07.04.2024</td>
                        <td>Ardèche</td>
                    </tr>
                    <tr>
                        <td>13.04. - 14.04.2024</td>
                        <td>Deutsche Meisterschaft im Kanu Freestyle (Plattling)</td>
                    </tr>
                    <tr>
                        <td>20.04.2024</td>
                        <td>Hüningen</td>
                    </tr>
                    <tr>
                        <td>01.05.2024</td>
                        <td>Moder (Drüsenheim nach Stattmann),<br />Fahrtenleitung Julian Fang</td>
                    </tr>
                    <tr>
                        <td>26.05. - 28.05.2024</td>
                        <td>Weltcup #1 Kanu Freestyle (Plattling)</td>
                    </tr>
                    <tr>
                        <td>30.05. - 02.06.2024</td>
                        <td>Weltcup #2 Kanu Freestyle (Plattling)<br />29.05. - 02.06.2024 Plattlinger Volksfest</td>
                    </tr>
                    <tr>
                        <td>29.06.2024</td>
                        <td>Hüningen</td>
                    </tr>
                    <tr>
                        <td>27.06. - 29.06.2024</td>
                        <td>Natural Games (Millau)</td>
                    </tr>
                    <tr>
                        <td>13.07. - 23.07.2024</td>
                        <td>Sommerfreizeit (Lienz),<br />Fahrtenleitung: Freddy Haak</td>
                    </tr>
                    <tr>
                        <td>31.08. oder 07.09.2024</td>
                        <td>Aktion mit dem Kinderheim</td>
                    </tr>
                    <tr>
                        <td>21.09.2024</td>
                        <td>Paddeln und Clean-Up in Regensburg,<br />Ansprechpartner: Leon Bast</td>
                    </tr>
                    <tr>
                        <td>28.09.2024</td>
                        <td>Wieslauterreinigung,<br />Fahrtenleitung: Helmut Baaden</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default EventsPage;