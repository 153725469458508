import Button from "react-bootstrap/esm/Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons'
import "./MembershipPage.css"

function MembershipPage(){

    return (
        <div>
            <h1>Mitgliedschaft</h1>
            <p>Mitglied werde ist ganz einfach. Aufnahmeantrag ausfüllen und beim nächsten Training oder der Veranstaltung bei uns abgeben.</p>
            <Button target="_blank" href="aufnahmeantrag_2022.pdf" className="download-button">Aufnahmeantrag herunterladen <FontAwesomeIcon icon={faFileArrowDown}/></Button>
        </div>
    );
}

export default MembershipPage;