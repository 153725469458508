import React from "react";
import PaddleNavItem from "./PaddleNavItem";

import "./PaddleNav.css"

type Props = {
}



function PaddleNav(props: Props) {
    return (
        <>
            <PaddleNavItem route="aktuelles" label="Aktuelles" />
            <PaddleNavItem route="termine" label="Termine" />
            <PaddleNavItem route="mitgliedschaft" label="Mitglied" subLabel="werden" />
            <PaddleNavItem route="kontakt" label="Kontakt" />
            <PaddleNavItem route="vorstand" label="Vorstand" />
            <PaddleNavItem route="beitraege" label="Beiträge" />
            <PaddleNavItem route="impressum" label="Impressum" />
        </>
    );
}

export default PaddleNav;