import React from 'react';
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './App.css';
import Logo from './Logo';
import PaddleNav from './components/PaddleNav/PaddleNavGroup';

function App() {
  const navigate = useNavigate();

  return (
    <Container>
      <Row style={{paddingBottom: '10px'}}>
        <Col>
          <div style={
            {
              width: '20%',
              float: "right"
            }
          } className="logo" onClick={() => navigate("")}>
            <Logo />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xxl={2}>
          <PaddleNav></PaddleNav>
        </Col>
        <Col xxl={7}><Outlet /></Col>
        <Col xxl={3}>
          <div>
            <h3>Freibadtraining:</h3>
            <p>Das Training im Rebmeerbad Bad Bergzabern <i>(Friedrich-Ebert-Straße 40, 76887 Bad Bergzabern)</i> findet aktuell <b>wöchentlich Mittwoch von 19:30 - 20:30 Uhr statt. Treffpunkt ist 19:00 Uhr vor dem Eingang zum Freibad.</b></p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col><div style={{ float: "right" }}>Copyrigth&copy; 1990 - {new Date().getFullYear()}</div></Col>
      </Row>
    </Container>
  );
}

export default App;
